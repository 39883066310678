import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { media } from '../components/Styles'

import Sidebar from "../components/homeMenu/sideBar"
import ArticleBanner from '../components/ArticleBanner'

import styled from "styled-components"

const NewsTemplate = ({ data, location , pageContext }) => {

  const { t } = useTranslation()
  var txt = ''
  if (typeof document !== "undefined") {
    var txtAux = document.createElement("textarea");
    txtAux.innerHTML = pageContext.excerpt
    txt = txtAux.value
  }


  function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  };

  var groups = groupBy(data.postgroup.nodes, 'date');
  var atualPost = groups[pageContext.date]

  var toSendPost = []
  atualPost.map((post) => {
    return toSendPost[post.tags.nodes[0].slug] = '/noticias'+post.uri
  })


  return (
    <Layout
      data={t("global", { returnObjects: true })}
      location={location}
    >
      <Seo
        title={pageContext.title}
        description={txt}
        img={pageContext.featuredImage.node.localFile.publicURL}
      />
      <Sidebar
        content={t("global", { returnObjects: true }).sidebar}
        projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
        projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
        news={toSendPost}
      />
      <ArticleBanner
        data={pageContext}
      />
      <StyledContent
        dangerouslySetInnerHTML={{ __html: pageContext.content }}
      />
      <StyledLink>
        <Link to='/noticias'>
          <p className="small">
            <span>
              {t("news", { returnObjects: true }).more}
            </span>
          </p>
        </Link>
      </StyledLink>
    </Layout>
  )
}

export default NewsTemplate

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 7.3%;
text-align:center;
padding: clamp(50px,10vw,180px) 7.3% clamp(50px,10vw,90px) 7.3%;

.wp-block-list li{
    width: fit-content !important; 
}

.d-inline a{
  display: inline !important;
  text-decoration: underline; 
}

    ${media.smarttv`
        align-items:center;
    `}

  .underline {
    text-decoration: underline;
  }
  .italic {
    font-style: italic;
  }

  .visibleLinks {
    a {
      display: inline;
      font-style: italic;
    }
  }

  .wp-block-image{
      display:flex;
      justify-content:center;
      flex-direction:column;
      align-items:center;
      width: fit-content;
      margin: 2rem auto; 
  }

  img{
      width:100%;
  }

  .wp-block-embed{
    width: fit-content;
    margin: 2rem auto;
  }

  h1 {
    text-transform: uppercase;
    margin: 3rem 0;
    text-align: center;
  }
  h2 {
    width: min(85%, 60ch);
    font-size: 1.5rem;
    margin: 1rem auto;
    text-align: center;
  }
  p, .table-odd, .table-even {
    line-height: 1.5em;
    margin: 1rem auto;
    text-align: justify;
    text-justify: inter-word;
    font-size: 1.3rem;
    max-width:1200px;
  }

  
  .table-odd, .table-even {

    .title-shadow{
      margin-bottom: 5vh;
    }
    .title-shadow strong{
      -webkit-box-shadow: 5px 15px 5px 0px rgb(0 0 0 / 10%);
      -moz-box-shadow: 5px 15px 5px 0px rgb(0 0 0 / 10%);
      box-shadow: 5px 15px 5px 0px rgb(0 0 0 / 10%);
    }
    td:first-child {
      width: 55%;
      padding-right: 5vh;
    }

    
    @media (max-width: 990px){
      
      .gatsby-image-wrapper{
        max-width: 36vh;
      }
      td:first-child {
        padding-right: 0.5vh;
        padding-left: 0.5vh;
      }
      .title-shadow{
        margin-bottom: 0vh;
      }
      .title-shadow strong{
        -webkit-box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 10%);
      -moz-box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 10%);
      box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 10%);
      }
    }
  }

  .center-gray{
    color: gray;
    width: 70%;
    margin: auto;
    text-align: center;
    font-weight: 300;
    font-family: system-ui;
  }

  .quote{
    font-style: italic;
    width: 60%;
    margin-left: auto;
    text-align: center;
    margin-right: auto;
  }
  @media (max-width: 990px){
    .table-even:last-of-type, .table-even:nth-last-of-type(2) {
      font-size: 0.925rem;
      .gatsby-image-wrapper {
        margin-bottom: 1vh;
        margin-top: 4vh;
        height: 100%;
        object-fit: contain;
        max-width: fit-content;
      }
      td{
        display: inherit;
      }
    }
  }
  li {
    line-height: 1.5;
  }
  ul {
    margin: 1rem auto;
    list-style: unset;
    width: min(85%, 70ch);
    font-size: 1.3rem;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }
  ol {
    margin: 1rem auto;
    list-style: decimal;
    width: min(85%, 70ch);
    font-size: 1.3rem;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }
  .asset {
    width: 65%;
    margin: 1rem auto;
  }
  .video {
    width: 100%;
    height: 60vh;
    mask-image: --webkit-radial-gradient(white, black);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    text-align: center;
    max-width: 55vw;
  }
  .video iframe{
    width: 100% !important;
    height: 60vh  !important;
    max-height: 100%  !important;
  }
  @media (min-width: 991px){
    .video {
      max-width: 55vw !important;
    }
  }
  @media only screen and (orientation: portrait),
    only screen and (max-width: 930px) {
    width: 74%;
    h1 {
      font-size: 1.6rem;
    }
    p,
    h2,
    .asset {
      width: min(100%, 60ch);
    }  
    .video {
      max-width: 80vw;
      height: 50vh !important;
      margin: auto;
    }
    .video iframe{
      height: 50vh !important;
    }
  }


  @media only screen and (max-width: 550px) {
    width: 85%;
    margin: 0 auto;

    h1 {
      font-size: 1.4rem;
    }
    h2 {
      font-size: 1.25rem;
    }
    p,
    li {
      font-size: 1rem;
      letter-spacing: normal;
    }
  }

  @media only screen and (max-width: 400px) {
    width: 90%;
    margin: 0 auto;

    h1 {
      font-size: 1.3rem;
    }
    h2 {
      font-size: 1.125rem;
    }
    p,
    li {
      font-size: 0.925rem;
      letter-spacing: normal;
    }
  }
`

const StyledLink = styled.div`
margin: 0 7.3%;
padding-left: 7.3%;
padding-right: 7.3%;
padding-bottom: 5%;
 
  a {
    width: fit-content;
    margin: auto;
    p {
      text-decoration: underline;
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: unset;
    margin: 5vh 0 5vh 0;

    a {
      p {
        font-size: 1.2rem;
      }
    }
  }
`

export const Json = graphql`
  query newsTemplate($language: String!) {
    locales: allLocale(filter: {ns: {in: ["news", "global", "projetosVenda", "portfolio"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
    postgroup:   allWpPost(sort: {fields: date, order: DESC}) {
      nodes {
        uri
        date(formatString: "dddd | DD MMMM YYYY", locale: "pt")
        id
        tags {
          nodes {
            slug
          }
        }
      }
    }
  }
`
